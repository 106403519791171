import { createLogic } from "redux-logic";
import * as Action from "./action";
import * as Types from "./types";
import _ from "lodash";

const updateSegmentsLogic = createLogic({
  type: Types.GET_BUS_ETA,
  warnTimeout: 0,
  processOptions: {
    dispatchMultiple: true,
  },
  async process({ action, httpClient, getState }: any, dispatch, done) {
    const { routes } = action.payload;
    let allRouteLocation: any[] = [];

    try {
      const routesRes = await httpClient.get(
        `${process.env.REACT_APP_SERVER_URL}/api/Routes?Ids=${_.map(
          routes,
          (route) => route.id
        )}`
      );

      routesRes.data.forEach((route: any) => {
        allRouteLocation = [...allRouteLocation, ...route.routeLocations];
      });

      console.log("ROUTE RES", { routesRes, allRouteLocation });

      // console.log("ALL ROUTE", allRouteLocation);
      // const ids = _.map(routes.data, "id");

      // //populate all routeLocations from many routes
      // await Promise.all(
      //   ids.map(async (id) => {
      //     const routesRes = await httpClient.get(
      //       `${process.env.REACT_APP_SERVER_URL}/api/routes/${id}`
      //     );
      //     if (routesRes) {
      //       allRouteLocation = [
      //         ...allRouteLocation,
      //         ...routesRes.data.routeLocations,
      //       ];
      //     }
      //   })
      // );

      const uniqueLocations: any[] = [];
      allRouteLocation.forEach((location) => {
        const target = _.find(uniqueLocations, {
          locationId: location.locationId,
        });
        if (target) {
          target.routeIds = _.uniq([...target.routeIds, location.routeId]);
        } else {
          uniqueLocations.push({ ...location, routeIds: [location.routeId] });
        }
      });

      // allRouteLocation.forEach(location => {
      //   if(_.find(locations, ))

      // });
      // dispatch(Action.updateLocationList(_.orderBy(locations, "id", "asc")));

      // console.log("LOC IDS", locations);
      // let allLocations: any[] = [];
      // await Promise.all(
      //   locationIds.map(async (id) => {
      //     const locationRes = await httpClient.get(
      //       `${process.env.REACT_APP_SERVER_URL}/api/locations/${id}`
      //     );
      //     if (locationRes) {
      //       allLocations = [...allLocations, locationRes.data];
      //     }
      //   })
      // );

      dispatch(Action.updateLocationList(uniqueLocations));

      // let etaByVehicle: any[] = [];

      // //filter all route location, select only item that has vehicle eta on it
      // const notEmptyVehicleEtas = _.filter(allRouteLocation, (item: any) => {
      //   return item.vehicleEtas.length !== 0;
      // });

      // // //populate all vehicle eta
      // notEmptyVehicleEtas.forEach((item: any) => {
      //   etaByVehicle = [...etaByVehicle, ...item.vehicleEtas];
      // });

      // dispatch(Action.getBusEtaSuccess(etaByVehicle));
    } catch (error) {
      console.log(error);
    }

    done();
  },
});

const getRouteTagsLogic = createLogic({
  type: Types.GET_ROUTE_TAGS,
  warnTimeout: 0,
  processOptions: {
    dispatchMultiple: true,
  },
  async process({ httpClient }: any, dispatch, done) {
    try {
      const routeTags = await httpClient.get(
        `${process.env.REACT_APP_SERVER_URL}/api/RouteTags?Filter={"TenantId": 16}&PageSize=100`
      );

      const tags = await httpClient.get(
        `${process.env.REACT_APP_SERVER_URL}/api/tags?Filter={"TenantId": 16}&PageSize=100`
      );

      const mappedTags = await Promise.all(
        tags.data.map((tagItem: any) => {
          return {
            ...tagItem,
            routes: _.filter(routeTags.data, (o) => {
              return o.tagId === tagItem.id;
            }),
          };
        })
      );
      const reversMappedTags = mappedTags.reverse();
      dispatch(Action.getRouteTagsSuccess(reversMappedTags));
    } catch (error) {
      console.log(error);
    }

    done();
  },
});

export default [updateSegmentsLogic, getRouteTagsLogic];
