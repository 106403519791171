import * as Types from "./types";

export const updateBusLocation = (data: any) => ({
  type: Types.UPDATE_BUS_LOCATION,
  payload: {
    data,
  },
});

export const updateEta = (data: any) => ({
  type: Types.UPDATE_ETA,
  payload: {
    data,
  },
});

export const shouldUpdateData = (shouldUpdate: boolean) => ({
  type: Types.SHOULD_UPDATE_DATA,
  payload: {
    shouldUpdate,
  },
});

export const updateLocationList = (data: any) => ({
  type: Types.UPDATE_LOCATION_LIST,
  payload: {
    data,
  },
});

export const getBusETA = (routes: RouteID[]) => ({
  type: Types.GET_BUS_ETA,
  payload: {
    routes,
  },
});

export const getBusEtaSuccess = (data: any) => ({
  type: Types.GET_BUS_ETA_SUCCESS,
  payload: {
    data,
  },
});

export const getRouteTags = () => ({
  type: Types.GET_ROUTE_TAGS,
});

export const getRouteTagsSuccess = (data: any) => {
  return {
    type: Types.GET_ROUTE_TAGS_SUCCESS,
    payload: {
      data,
    },
  };
};
