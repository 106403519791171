import axios from "axios";
import { createStore, applyMiddleware } from "redux";
import { createLogicMiddleware } from "redux-logic";
import { createLogger } from "redux-logger";
import rootReducer from "./rootReducer";
import logic from "./rootLogic";

const dependencies = {
  httpClient: axios,
};
//@ts-ignore
const logicMiddleware = createLogicMiddleware(logic, dependencies);
// const logger = createLogger();
const middlewares = [logicMiddleware];

//@ts-ignore
middlewares.push(createLogger());

export default () => {
  let store = createStore(rootReducer, applyMiddleware(...middlewares));
  return { store };
};
