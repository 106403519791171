/* eslint-disable import/no-anonymous-default-export */
import * as Types from "./types";
import _ from "lodash";
import moment from "moment";

const initialState = {
  busData: [],
  schedules: [],
  locations: [],
  routeTags: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Types.UPDATE_BUS_LOCATION:
      let busData: any[] = _.cloneDeep(state.busData);
      const { data } = action.payload;

      const indexToUpdate = _.findIndex(busData, (o: any) => {
        return o.assignment.id === data.assignment.id;
      });

      const fakeIndexToUpdate = _.findIndex(busData, (o: any) => {
        return o.assignment.id === data.assignment.id + 99999;
      });

      // console.log("FIND", { busData, indexToUpdate, fakeIndexToUpdate });

      const estimatedBusToNextLocation = {
        ...data,
        assignment: { id: data.assignment.id + 99999 },
        toLocation:
          data.nextLocation.id !== 0
            ? data.fromLocation === data.nextLocation
              ? data.fromLocation
              : data.nextLocation
            : 0,
        fromLocation: data.toLocation,
        hide: true,
        eta: moment(data.eta).add(5, "minutes"),
      };

      if (indexToUpdate !== -1) {
        busData[indexToUpdate] = data;

        if (fakeIndexToUpdate !== -1) {
          busData[fakeIndexToUpdate] = estimatedBusToNextLocation;
        }
        // console.log("UPDATE", busData);
      } else {
        if (data.nextLocation.id !== 0) {
          busData = [...busData, data, estimatedBusToNextLocation];
        } else {
          busData = [...busData, data];
        }

        // console.log("CREATE", busData);
      }
      return {
        ...state,
        busData,
      };

    case Types.GET_BUS_ETA_SUCCESS:
      return {
        ...state,
        busData: action.payload.data,
      };
    case Types.UPDATE_LOCATION_LIST:
      return {
        ...state,
        locations: action.payload.data,
      };

    case Types.GET_ROUTE_TAGS_SUCCESS:
      return {
        ...state,
        routeTags: action.payload.data,
      };
    default:
      return state;
  }
};
