import React, { useState, useEffect, useCallback } from "react";
import caretActive from "./images/caret-active.svg";
import caretInactive from "./images/caret-inactive.svg";
import caretRight from "./images/caret-right.svg";
import closeIcon from "./images/close.svg";

import wrs from "./images/logo.svg";
import bus from "./images/bus.png";
import marker from "./images/marker.svg";
import flag from "./images/flag.svg";

import plotigoLogo from "./images/plotigo-logo.svg";
import wtLogo from "./images/wt-logo.png";

import moment from "moment";
import _ from "lodash";
import { Provider } from "react-redux";
import configStore from "./redux/configStore";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  updateBusLocation,
  getBusETA,
  updateEta,
  shouldUpdateData,
  getRouteTags,
} from "./redux/action";

import GoogleMapReact from "google-map-react";

import "./App.css";

const colors = ["#9e2759", "#4caf50"];

const { store } = configStore();

const signalR = require("@microsoft/signalr");
const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${process.env.REACT_APP_SERVER_URL}/SignalRHubs/RealtimeVehicle`, {
    accessTokenFactory: () => "",
    transport: signalR.HttpTransportType.WebSockets,
    skipNegotiation: true,
  })
  .configureLogging(signalR.LogLevel.Information)
  .build();

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
};

const mapStateToProps = ({ main }: any) => ({
  busData: main.busData,
  locations: main.locations,
  routeTags: main.routeTags,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    { getBusETA, updateBusLocation, updateEta, shouldUpdateData, getRouteTags },
    dispatch
  );
};

const Main = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ busData, ...props }: any) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [activeBuses, setActiveBuses] = useState<any[]>([]);
  const [locations, setLocations] = useState<any>([]);
  const [activeEta, setActiveEta] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);

  const [isConnected, setConnected] = useState(false);
  const [routeToSubscribe, setRouteToSubscribe] = useState<any[]>([]);

  const { width } = useViewport();

  //Initial Setup. Begin Socket Connection and Get Route Tags Data
  useEffect(() => {
    startConnection();
    props.getRouteTags();
  }, []);

  //Listen for Route Tags props changes. When changed, populate RouteID Data and set List of route to be subscribed. set to state.
  useEffect(() => {
    const routeToSub: RouteID[] = [];

    props.routeTags.forEach((routeTag: any) => {
      routeTag.routes.forEach((route: any) => {
        routeToSub.push({ id: route.routeId, routeLocationIds: [] });
      });
    });

    setRouteToSubscribe(routeToSub);
    setActiveIndex(0);
  }, [props.routeTags]);

  const startConnection = async () => {
    try {
      await connection.stop();
      await connection.start();

      connection.on("BroadcastEta", (data: any) => {
        // console.log(`BROADCAST DATA FROM VEHICLE #${data.vehicle.id}`, data);
        props.updateBusLocation(data);
      });

      setConnected(true);

      connection.onclose(() => {
        setConnected(false);
        // console.log("CONNECTION CLOSED");
        startConnection();
      });

      // console.log("SignalR Connected.");
    } catch (err) {
      // console.log("SOMETHING WRONG", err);

      setConnected(false);
      startConnection();
    }
  };

  useEffect(() => {
    if (isConnected && routeToSubscribe.length !== 0) {
      props.getBusETA(routeToSubscribe);
      routeToSubscribe.forEach(async (route: RouteID) => {
        try {
          // console.log(`SUBSCRIBE TO Route ${route.id}`);
          await connection.invoke("JoinGroup", `route:${route.id}`);

          // console.log(`Subscribed to Route: ${route.id}`);
        } catch (err) {
          console.error(err);
        }
      });
    }
  }, [isConnected, routeToSubscribe]);

  const changeActiveIndex = useCallback(
    (index: number) => {
      setActiveIndex(
        index === activeIndex ? (width >= 1130 ? index : -1) : index
      );
    },
    [activeIndex]
  );

  useEffect(() => {
    const activeRouteTags = props.routeTags[activeIndex];

    if (activeRouteTags) {
      const activeRouteIds = _.map(
        activeRouteTags.routes,
        (route) => route.routeId
      );

      let colorIndexSelector = 0;

      const displayLocations = _.filter(props.locations, (location) =>
        location.routeIds.some((o: Number) => activeRouteIds.includes(o))
      );

      displayLocations.forEach((displayLocation) => {
        displayLocation.color = colors[colorIndexSelector];
        colorIndexSelector++;

        const locationBusData = _.filter(
          busData,
          (o) =>
            o.toLocation.id === displayLocation.locationId &&
            activeRouteIds.includes(o.route.id)
        );

        displayLocation.busData = _.orderBy(locationBusData, (item: any) => {
          return moment(item.eta);
        });
      });

      setLocations(displayLocations);
    }
  }, [busData, activeIndex, props.locations]);

  return (
    <div className="App">
      <header className="Navbar">
        <img className="Logo" src={wrs} alt="Logo" />
      </header>
      <div className="Wrapper">
        <div className="Selection-Wrapper">
          <h3 className="Route-Title">Shuttle Service</h3>

          {_.isEmpty(props.routeTags) ? (
            <div className="Title-Loading-Wrapper">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            props.routeTags.map((item: any, index: number) => {
              return (
                <div
                  key={item.id}
                  className={`Route-Button ${
                    activeIndex === index ? "active" : ""
                  }  `}
                >
                  <div
                    className="Button-Text-Wrapper"
                    onClick={() => {
                      changeActiveIndex(index);
                    }}
                  >
                    {item.name}
                    {activeIndex === index ? (
                      <img className="Caret-Icon" src={caretActive} />
                    ) : (
                      <img className="Caret-Icon" src={caretInactive} />
                    )}
                  </div>

                  {activeIndex === index && width < 1130 && (
                    <>
                      <div className="Mini-Map">
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyDYGvy_HvsUfvJ_7_d4PmOH201jIFNn-iM",
                          }}
                          defaultCenter={{
                            lat: 1.3421639165087222,
                            lng: 103.83772555396588,
                          }}
                          // onClick={onMapClick}
                          defaultZoom={activeIndex === 0 ? 15 : 13}
                        >
                          {locations?.map((location: any) => (
                            <FlagMarker
                              color={
                                !_.isEmpty(location.busData)
                                  ? location.color
                                  : "#a6a6a6"
                              }
                              lat={location.location.lat}
                              lng={location.location.lng}
                            />
                          ))}

                          {locations.map((busLocation: any) => {
                            return busLocation.busData?.map(
                              (bus: any, i: number) => {
                                if (!bus.hide) {
                                  return (
                                    <BusMarker
                                      color={busLocation.color}
                                      bus={bus}
                                      key={i}
                                      lat={bus.vehicle.lastLocationLat}
                                      lng={bus.vehicle.lastLocationLng}
                                    />
                                  );
                                } else {
                                  return <></>;
                                }
                              }
                            );
                          })}
                        </GoogleMapReact>
                      </div>
                      <div className="Route-Wrapper-Mini">
                        <div className="Route-Header">
                          <div className="Text-Wrapper">
                            <span className="Text-Title">Pick-up</span>
                            <div className="Column-Flex">
                              <span className="Text-Detail">Estimated</span>
                              <span className="Text-Detail">
                                Time of Arrival
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="Route-Body">
                          {_.isEmpty(locations) ? (
                            <div className="Title-Loading-Wrapper">
                              <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          ) : (
                            locations.map(({ location, busData }: any) => (
                              <div className="Text-Wrapper Border-Bottom">
                                <div className="Segment-Title-Wrapper">
                                  <span className="Segment-Title">
                                    {location.name}
                                  </span>
                                  <span className="Segment-Description">
                                    {location.description}
                                  </span>
                                </div>
                                {_.isEmpty(busData) ? (
                                  <span className="Text-Time">No Service</span>
                                ) : (
                                  <></>
                                )}

                                {busData?.map((item: any, index: number) => {
                                  const now = moment();
                                  const remaining = moment
                                    .duration(moment(item.eta).diff(now))
                                    .asMinutes();

                                  if (remaining < 1 && remaining > 0.25) {
                                    return (
                                      <span key={index} className="Text-Note">
                                        ARRIVING
                                      </span>
                                    );
                                  } else if (remaining <= 0.25) {
                                    return (
                                      <span key={index} className="Text-Note">
                                        ARRIVED
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span key={index} className="Text-Time">
                                        {Math.floor(remaining)}
                                        <span className="Text-Min">min</span>
                                      </span>
                                    );
                                  }
                                })}
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })
          )}
        </div>

        {/* Only Show if layout is at least 1130px wide */}
        {width >= 1130 && (
          <div className="Map-Wrapper">
            <div
              style={{
                height: "70vh",
                width: "60%",
                backgroundColor: "#fafafa",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDYGvy_HvsUfvJ_7_d4PmOH201jIFNn-iM",
                }}
                defaultCenter={{
                  lat: 1.3432976093860602,
                  lng: 103.84267851081255,
                }}
                defaultZoom={15}
              >
                {locations?.map((location: any) => (
                  <FlagMarker
                    color={
                      !_.isEmpty(location.busData) ? location.color : "#a6a6a6"
                    }
                    lat={location.location.lat}
                    lng={location.location.lng}
                  />
                ))}

                {locations.map((busLocation: any) => {
                  return busLocation.busData?.map((bus: any, i: number) => {
                    if (!bus.hide) {
                      return (
                        <BusMarker
                          color={busLocation.color}
                          bus={bus}
                          key={i}
                          lat={bus.vehicle.lastLocationLat}
                          lng={bus.vehicle.lastLocationLng}
                        />
                      );
                    } else {
                      return <></>;
                    }
                  });
                })}
              </GoogleMapReact>
            </div>
            <div className="Route-Wrapper">
              <div className="Route-Header">
                <div className="Text-Wrapper">
                  <span className="Text-Title">Pick-up</span>
                  <span className="Text-Detail">Estimated Time of Arrival</span>
                </div>
              </div>
              <div className="Route-Body">
                {_.isEmpty(locations) ? (
                  <div className="Title-Loading-Wrapper">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  locations.map(({ location, busData }: any) => (
                    <div className="Text-Wrapper Border-Bottom">
                      <div className="Segment-Title-Wrapper">
                        <span className="Segment-Title">{location.name}</span>
                        <span className="Segment-Description">
                          {location.description}
                        </span>
                      </div>
                      {_.isEmpty(busData) ? (
                        <span className="Text-Time">No Service</span>
                      ) : (
                        <></>
                      )}

                      {busData?.map((item: any, index: number) => {
                        const now = moment();
                        const remaining = moment
                          .duration(moment(item.eta).diff(now))
                          .asMinutes();

                        if (remaining < 1 && remaining > 0.25) {
                          return (
                            <span key={index} className="Text-Note">
                              ARRIVING
                            </span>
                          );
                        } else if (remaining <= 0.25) {
                          return (
                            <span key={index} className="Text-Note">
                              ARRIVED
                            </span>
                          );
                        } else {
                          return (
                            <span key={index} className="Text-Time">
                              {Math.floor(remaining)}
                              <span className="Text-Min">min</span>
                            </span>
                          );
                        }
                      })}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        )}
        <div className="Logo-Wrapper">
          <div className="Logo-Container">
            <div className="Plotigo-Wrapper">
              <p>Powered by</p>
              <section>
                <a href="https://www.plotigo.app/">
                  <img
                    className="Plotigo-Logo"
                    src={plotigoLogo}
                    alt="plotigo-logo"
                  />
                </a>
              </section>
            </div>
            <div className="Wt-Wrapper">
              {width > 1130 ? <p>| Operated by</p> : <p>Operated by</p>}
              <section className="Wt-Wrapper">
                <a href="https://www.woodlandstransport.com.sg/">
                  <img className="Wt-Logo" src={wtLogo} alt="wt-logo" />
                </a>
              </section>
            </div>
          </div>
        </div>
        {showModal && (
          <div className="Modal">
            <div className="Modal-Content">
              <div
                onClick={() => {
                  setShowModal((state) => !state);
                }}
                className="CloseButton"
              >
                <img className="Close-Icon" src={closeIcon} />
              </div>
              <iframe
                className="Modal-Iframe"
                src="https://form.jotform.com/woodlandstransport/mandai-express"
                title="description"
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

const BusMarker = ({ bus, ...props }: any) => {
  const getETA = useCallback(() => {
    const now = moment();

    const remaining = moment.duration(moment(bus.eta).diff(now)).asMinutes();

    if (remaining < 1 && remaining > 0.25) {
      return (
        <div
          className="Marker-ETA-Arriving"
          style={{ backgroundColor: props.color }}
        >{`ARRIVING`}</div>
      );
    } else if (remaining <= 0.25) {
      return (
        <div
          className="Marker-ETA-Arr"
          style={{ backgroundColor: props.color }}
        >{`ARRIVED`}</div>
      );
    } else {
      return (
        <div
          className="Marker-ETA"
          style={{ backgroundColor: props.color }}
        >{`${Math.floor(remaining)}min`}</div>
      );
    }
  }, [bus]);

  return (
    <div className="Marker-Wrapper">
      {getETA()}
      <img className="Marker-Icon" src={marker} alt="Bus Marker" />
    </div>
  );
};

export const FlagMarker = (props: any) => {
  return (
    <div className="Marker-Wrapper-Flag">
      <div>
        <svg
          width="41"
          height="44"
          viewBox="0 0 41 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41 20.5C41 31.8218 31.8218 41 20.5 41C9.17816 41 0 31.8218 0 20.5C0 9.17816 9.17816 0 20.5 0C31.8218 0 41 9.17816 41 20.5Z"
            fill={`${props.color ? props.color : "#016901"}`}
          />
          <path
            d="M21 44L24.4641 40.25H17.5359L21 44Z"
            fill={`${props.color ? props.color : "#016901"}`}
          />
          <g clip-path="url(#clip0)">
            <path
              d="M22.8125 19.0625H28.0625C28.304 19.0625 28.5 18.8665 28.5 18.625C28.5 18.3835 28.304 18.1875 28.0625 18.1875H22.8125C22.571 18.1875 22.375 18.3835 22.375 18.625C22.375 18.8665 22.571 19.0625 22.8125 19.0625Z"
              fill="white"
            />
            <path
              d="M28.9375 19.9375H21.9375C21.696 19.9375 21.5 20.1335 21.5 20.375V25.625C21.5 25.8665 21.696 26.0625 21.9375 26.0625H28.9375C29.179 26.0625 29.375 25.8665 29.375 25.625V20.375C29.375 20.1335 29.179 19.9375 28.9375 19.9375ZM28.5 25.1875H22.375V20.8125H28.5V25.1875H28.5Z"
              fill="white"
            />
            <path
              d="M31.5625 19.0625C31.321 19.0625 31.125 19.2585 31.125 19.5V18.625C31.125 17.4193 30.1432 16.4375 28.9375 16.4375H21.9375C20.7318 16.4375 19.75 17.4193 19.75 18.625V19.5C19.75 19.2585 19.554 19.0625 19.3125 19.0625C19.071 19.0625 18.875 19.2585 18.875 19.5V23C18.875 23.2415 19.071 23.4375 19.3125 23.4375C19.554 23.4375 19.75 23.2415 19.75 23V28.25C19.75 28.4915 19.946 28.6875 20.1875 28.6875H20.6251C20.6251 29.1696 21.0179 29.5625 21.5 29.5625H22.375C22.8572 29.5625 23.25 29.1696 23.25 28.6875H27.625C27.625 29.1696 28.0179 29.5625 28.5 29.5625H29.375C29.8571 29.5625 30.25 29.1696 30.25 28.6875H30.6875C30.929 28.6875 31.125 28.4915 31.125 28.25V23C31.125 23.2415 31.321 23.4375 31.5625 23.4375C31.804 23.4375 32 23.2415 32 23V19.5C32 19.2585 31.804 19.0625 31.5625 19.0625ZM30.25 27.8125H20.625V18.625C20.625 17.9013 21.2139 17.3125 21.9375 17.3125H28.9375C29.6612 17.3125 30.25 17.9013 30.25 18.625V27.8125H30.25Z"
              fill="white"
            />
            <path
              d="M15.8125 26.9374H14.0625C13.3389 26.9374 12.75 27.5262 12.75 28.2499V29.1249C12.75 29.3664 12.946 29.5624 13.1875 29.5624C13.429 29.5624 13.625 29.3664 13.625 29.1249V28.6874H16.25V29.1249C16.25 29.3664 16.446 29.5624 16.6875 29.5624C16.929 29.5624 17.125 29.3664 17.125 29.1249V28.2499C17.125 27.5263 16.5361 26.9374 15.8125 26.9374Z"
              fill="white"
            />
            <path
              d="M20.1875 15.5625C20.429 15.5625 20.625 15.3665 20.625 15.125V14.25C20.625 11.5961 18.4664 9.4375 15.8125 9.4375C13.1587 9.4375 11 11.5961 11 14.25V29.125C11 29.3665 11.196 29.5625 11.4375 29.5625C11.679 29.5625 11.875 29.3665 11.875 29.125V19.7572L19.4254 15.5625H20.1875ZM11.875 14.25C11.875 12.0791 13.6416 10.3125 15.8125 10.3125C17.9834 10.3125 19.75 12.0791 19.75 14.25V14.6875H11.875V14.25ZM11.875 18.7563V15.5625H17.6237L11.875 18.7563Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="21"
                height="21"
                fill="white"
                transform="translate(11 9)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <Main></Main>
    </Provider>
  );
};

export default App;
