export const UPDATE_BUS_LOCATION = "UPDATE_BUS_LOCATION";
export const UPDATE_ETA = "UPDATE_ETA";

export const GET_BUS_ETA = "GET_BUS_ETA";
export const GET_BUS_ETA_SUCCESS = "GET_BUS_ETA_SUCCESS";
export const UPDATE_LOCATION_LIST = "UPDATE_LOCATION_LIST";

export const SHOULD_UPDATE_DATA = "SHOULD_UPDATE_DATA";

export const GET_ROUTE_TAGS = "GET_ROUTE_TAGS";
export const GET_ROUTE_TAGS_SUCCESS = "GET_ROUTE_TAGS_SUCCESS";
